<template>
  <!-- 村社发展治理 -->
  <div class="development">
     <div class="center-container">
      <div class="left-content">
        <div class="s-title s-title-b">
          <span class="decoration"></span>
          <span class="title">村社发展治理</span>
        </div>
        <div class="change">
          <div
            v-for="(item,index) in types"
            :key="index"
            :class="{'active': currentIndex == index}"
            class="change-item"
            @click="changeType(item, index)">
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="s-title s-title-b">
          <span >村社发展治理</span> >
          <span>{{currentType.name}}</span>
        </div>
        <div class="info">
          <div class="table-card">
            <div class="docs">
              <div class="docs-item" v-for="(item, index) in msgs" :key="index" @click="showDetail(item)">
                <span>
                  <img src="imgs/icon-decoration.png" alt="">
                  <span>{{item.title}}</span>
                </span>
                <span style="color: #a5a5a5;">{{item.createTime}}</span>
              </div>
            </div>
            <div class="pagenation">
              <el-pagination
                background
                layout="total, slot, prev, pager, next, slot"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="total"
                @current-change="currentChange">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileLists } from '../../api/development'

export default {
  data() {
    return {
      types: [
        {name: '基层党组织建设', type: 11},
        {name: '村民代表大会', type: 12},
        {name: '农村集体经济组织', type: 13},
      ],
      currentIndex: 0,
      currentType: {},
      msgs: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
    }
  },
  watch: {
    $route: {
      handler: function() {
        let index = Number(this.$route.query.index);
        this.changeType(this.types[index], index)
      },
      immediate: true
    }
  },
  methods: {
    changeType(item, index) {
      // 切换类型
      this.currentIndex = index;
      this.currentType = item;
      this.$router.push({name: 'developmentManage', query: {index: index}})
      this.msgs = [];
      this.currentPage = 1;
      this.initData();
    },
    async initData() {
      // 获取数据
      let params = {
        current: this.currentPage,
        size: this.pageSize,
        articleFid: '5',
        articleSid: this.currentType.type,
      }
      let res = await getFileLists(params);
      let {code, data} = res;
      if(code == 0) {
        this.msgs = data.records;
        this.total = data.total;
      }
    },
    currentChange(val) {
      // 切换分页
      this.currentPage = val;
      this.initData();
    },
    async showDetail(item) {
      // 详情
      this.$router.push({name: 'newsPage', query: {c: item.id}})
    }
  }
}
</script>

<style lang="less" scoped>
.development {
  // background: #fff;
  padding-top: 20px;
  padding-bottom: 70px;
  .docs {
    // height: 640px;
    margin-bottom: 8px;
    // overflow-y: auto;
    // margin-top: 25px;
    padding: 10px 16px;
    .docs-item {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 56px;
      color: #333333;
      padding-right: 8px;
      margin-bottom: 14px;
      border-bottom: 1px solid #c9c9c9;
      cursor: pointer;
      img {
        margin-right: 9px;
      }
    }
  }
}
</style>

<style lang="less">
.development {
  .pagenation {
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      border-radius: 0;
    }
  }
}
</style>

